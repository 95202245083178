import React from "react"


import NotFoundTemplate from "../page-templates/not-found.template"

const NotFoundPage = () => (
 
  <>
    <NotFoundTemplate />
  </>
 
)

export default NotFoundPage
